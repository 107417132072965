import React, { useEffect } from 'react'
import SEO from 'components/SEO'
import Layout from 'components/Layout'

const report = '/docs/Cliente-versao4.pdf'

const PrivacyClientsVersion3PDF = () => {
  useEffect(() => {
    if (window) {
      window.location = report
    }
  })
  return (
    <Layout>
      <SEO
        description="Políticas de privacidade de clientes PDF válido de 09/04/2021 até 17/08/2022"
        title="Políticas de privacidade de clientes PDF válido de 09/04/2021 até 17/08/2022"
      />
    </Layout>
  )
}

export default PrivacyClientsVersion3PDF
